<template>
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 18H6V14H12M21 14C21 13.219 21 12 21 12L20.1608 7.80388C20.0673 7.33646 19.6569 7 19.1802 7H4.8198C4.34312 7 3.93271 7.33646 3.83922 7.80388L3 12V13.5C3 13.7761 3.22386 14 3.5 14H4V19C4 19.5523 4.44772 20 5 20H13C13.5523 20 14 19.5523 14 19V14H18V19C18 19.5523 18.4477 20 19 20C19.5523 20 20 19.5523 20 19V14M20 5C20 4.44772 19.5523 4 19 4H5C4.44772 4 4 4.44772 4 5C4 5.55228 4.44772 6 5 6H19C19.5523 6 20 5.55228 20 5Z"
    />
  </svg>
</template>
