<template>
  <svg
    fill="none"
    height="20"
    viewBox="0 0 21 20"
    width="21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_66_2460)">
      <path
        d="M12.8528 1.57095V7.95238H0V1.57095C0 0.703333 0.636893 0 1.42278 0H4.70105V3.50048C4.70105 3.98429 5.0579 4.3781 5.49613 4.3781H7.35669C7.79493 4.3781 8.15177 3.98429 8.15177 3.50048V0H11.43C12.2159 0 12.8528 0.703333 12.8528 1.57095Z"
      />
      <path
        d="M12.8528 9.19047C12.8528 9.55857 12.5824 9.85714 12.249 9.85714H0.603784C0.270392 9.85714 0 9.55857 0 9.19047C0 8.82238 0.270392 8.5238 0.603784 8.5238H12.249C12.5824 8.5238 12.8528 8.82238 12.8528 9.19047Z"
      />
      <path
        d="M0 10.4286H12.8298V15.2862C12.8298 16.1538 12.1506 16.8572 11.3128 16.8572H8.56748C8.57346 16.7786 8.57621 16.6991 8.57621 16.6191C8.57621 14.8572 7.19666 13.4286 5.49522 13.4286C3.79377 13.4286 2.41422 14.8572 2.41422 16.6191C2.41422 16.6991 2.41698 16.7786 2.42295 16.8572H1.51705C0.6792 16.8572 0 16.1538 0 15.2862V10.4286H0Z"
      />
      <path
        d="M20.9353 10.1467L18.8912 5.76193C18.7827 5.52908 18.5546 5.38098 18.3049 5.38098H14.3314C13.9722 5.38098 13.6807 5.68289 13.6807 6.05479V16.0881C13.6807 16.4429 13.946 16.7334 14.2817 16.7596C14.2794 16.7129 14.2785 16.6662 14.2785 16.6191C14.2785 14.8572 15.658 13.4286 17.3595 13.4286C19.0609 13.4286 20.4405 14.8572 20.4405 16.6191C20.4405 16.6648 20.4395 16.71 20.4372 16.7553C20.7555 16.7115 21.0001 16.4291 21.0001 16.0881V10.44C21.0001 10.3386 20.978 10.2381 20.9353 10.1467ZM18.762 10.2381H15.0869C14.8533 10.2381 14.6643 10.0419 14.6643 9.80051V7.1967C14.6643 6.95479 14.8533 6.75908 15.0869 6.75908H17.7816C17.9559 6.75908 18.1122 6.86955 18.1752 7.03765L19.1556 9.64146C19.2637 9.92812 19.0595 10.2381 18.762 10.2381Z"
      />
      <path
        d="M5.4953 18.9999C6.76514 18.9999 7.79455 17.934 7.79455 16.619C7.79455 15.304 6.76514 14.238 5.4953 14.238C4.22546 14.238 3.19604 15.304 3.19604 16.619C3.19604 17.934 4.22546 18.9999 5.4953 18.9999Z"
      />
      <path
        d="M17.3593 18.9999C18.6292 18.9999 19.6586 17.934 19.6586 16.619C19.6586 15.304 18.6292 14.238 17.3593 14.238C16.0895 14.238 15.0601 15.304 15.0601 16.619C15.0601 17.934 16.0895 18.9999 17.3593 18.9999Z"
      />
      <path
        d="M5.35718 0H7.56446V3.22286C7.56446 3.52048 7.33132 3.7619 7.04391 3.7619H5.87773C5.59032 3.7619 5.35718 3.52048 5.35718 3.22286V0Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_66_2460">
        <rect
          fill="#808080"
          height="20"
          width="21"
        />
      </clipPath>
    </defs>
  </svg>
</template>
